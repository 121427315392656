<template>
  <div class="bg-blueGray-800 h-screen">
    <main>
      <div class="container relative mx-auto">
        <div class="title">
          <h1 class="pb-4">Self Check-In</h1>
          <p class="mb-12">
            Scan the participant's QR code with the QR scanner or enter manually
          </p>
          <div class="input-btn-container">
            <div class="input-btn">
              <input
                type="text"
                v-model="id"
                required
                class="border w-full"
                placeholder="Enter ID"
                @input="handleInput"
                ref="idInput"
              />
              <button
                @click="checkIn"
                class="check-in-btn"
                :disabled="!id"
              >
                Check-In
              </button>
            </div>
          </div>
        </div>
        <div v-if="isCheckedIn && !errorMsg" class="user-detail-container">
          <table>
            <tr>
              <td><strong>Name</strong></td>
              <td>&nbsp;:&nbsp;{{ name }}</td>
            </tr>
            <tr>
              <td><strong>Company Name</strong></td>
              <td>&nbsp;:&nbsp;{{ companyName }}</td>
            </tr>
            <tr>
              <td><strong>Table Name</strong></td>
              <td>&nbsp;:&nbsp;{{ tableName }}</td>
            </tr>
          </table>

          <div class="text-center mt-10">
              <button
                @click="reloadPage"
                class="bg-white text-blueGray-800 active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
              >
                Scan another QR Code
              </button>
          </div>
        </div>
        <div v-else>
          <p class="error-msg">{{ errorMsg }}</p>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import axios from "axios"
import printJS from "print-js";
import topBanner from "@/assets/img/a29035be.png";
import Speech from 'speak-tts'

export default {
  data() {
    return {
      id: "",
      isCheckedIn: false,
      name: "",
      companyName: "",
      tableName: "",
      errorMsg: ""
    }
  },
  mounted() {
    this.$refs.idInput.focus()
  },
  methods: {
    async checkIn() {
      try {
        const speech = new Speech();

        speech.init({
            volume: 1,
            lang: "en-US",
            rate: 1,
            pitch: 1,
            voice: 'Google US English',
        }).then(() => {
            speech.speak({
                text: 'Welcome Kobe Cheong, check-in successful!',
            });
        }).catch(e => {
            console.error("An error occurred during initialization:", e);
        });

        //printJS(topBanner, 'image');
        printJS({printable: topBanner, type: 'image', header: '<center><table style="font-size:13px;font-family:calibri;text-align:center"><tr><td>Kobe Cheong</td></tr><tr><td>DIV Technology</td></tr><tr></center>'})

        this.errorMsg = ''
        const res = await axios.post(
          process.env.VUE_APP_API_ENDPOINT + "/check-in-guest/" + this.id
        )

        this.name = res.data.full_name
        this.companyName = res.data.company_name
        this.tableName = res.data.table_name

        if (!this.name || !this.companyName) {
          this.errorMsg = 'Data not available'
        } else {
          this.isCheckedIn = true
        }
      } catch (error) {
        this.isCheckedIn = false
        this.errorMsg =
          error.response.data.message ||
          "Something went wrong. Please try again."
      } finally {
        // Clear the input field and focus it again
        this.id = ""
        this.$refs.idInput.focus()
      }
    },
    handleInput() {
      if (this.id.length == 8) {
        this.checkIn()
      }
    },
    reloadPage() {
      window.location.reload();
    }
  }
}
</script>

<style lang="sass" scoped>
.input-btn-container
  display: flex
  justify-content: center
  border-radius: 15px
  .input-btn
    border: 1px solid white
    border-radius: 15px
    overflow: hidden
    display: flex
    @media (max-width: 425px)
      flex-wrap: wrap
    input
      background-color: transparent
      border: none
    .check-in-btn
      background-color: white
      color: black
      height: 100%
      padding: 0.75rem
      width: 120px
      @media (max-width: 425px)
        width: 100%
        height: auto
</style>
