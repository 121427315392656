<template>
  <div>
    <main>
      <div style="background-color: #070f26; min-height: 100%;">
        <br class="desktop-break" />
        <img alt="ntt-data-banner" :src="topBanner" class="mx-auto responsive-image" />
      </div>
      <section class="relative block w-full min-h-screen" style="background: #070f26">
          <div class="flex flex-wrap justify-center"> 
            <div class="form-container">
              <div
                class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-b-lg bg-blueGray-200"
              >
                <form @submit.prevent="submit">
                  <div v-if="!isSuccess" class="flex-auto p-5 lg:p-10">
                    <h4 class="text-2xl font-semibold">Welcome to DIV Technology FundFest 2024!</h4>
                    <br>
                    <div class="flex flex-col" style="gap: 1.5rem;">
                      <div class="flex row mt-4">
                        <!-- First Name -->
                         <div class="relative col mr-2">
                          <label
                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="first-name"
                          >
                            First Name
                          </label>
                          <input
                            type="text"
                            v-model="form.firstName"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="First Name"
                            required
                          />
                        </div> 
                        <!-- Last Name -->
                        <div class="relative col ml-2">
                          <label
                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="last-name"
                          >
                            Last Name
                          </label>
                          <input
                            type="text"
                            v-model="form.lastName"
                            class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Last Name / Surname / Family Name"
                            required
                          />
                        </div>
                      </div>
                      <!-- Designation -->
                      <div class="relative row mt-4">
                        <label
                          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="designation"
                        >
                          Designation
                        </label>
                        <input
                          type="text"
                          v-model="form.designation"
                          class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Designation"
                          required
                        />
                      </div>
                      <!-- Company Name -->
                      <div class="relative row mt-4">
                        <label
                          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="company-name"
                        >
                          Company Name
                        </label>
                        <input
                          type="company-name"
                          v-model="form.companyName"
                          class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Company Name"
                          required
                        />
                      </div>
                      <!-- Nature of Business -->
                      <div class="relative row mt-4">
                        <label
                          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="nature-of-business"
                        >
                          Nature of Business
                        </label>
                        <input
                          type="nature-of-business"
                          v-model="form.natureOfBusiness"
                          class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Nature of Business"
                          required
                        />
                      </div>
                      <!-- Email -->
                      <div class="relative row mt-4">
                        <label
                          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="email"
                        >
                          Email Address
                        </label>
                        <input
                          type="email"
                          v-model="form.email"
                          class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          placeholder="Business Email Address"
                          required
                        />
                      </div>
                      <div class="flex row mt-4">
                        <!-- Mobile Number -->
                        <div class="relative row w-full">
                          <label
                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="mobile-number"
                          >
                            Contact Number
                          </label>
                          <div class="flex">
                            <div class="relative">
                              <div
                                class="country-code-dropdown appearance-none border-0 px-3 py-3 text-blueGray-600 bg-white rounded-l text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                                @click="toggleDropdown"
                                ref="countryCodeDropdown"
                              >
                                +{{ form.countryCode }}
                              </div>
                              <div
                                v-show="isDropdownVisible"
                                class="custom-dropdown"
                              >
                                <div
                                  v-for="countryCode in countryCodes"
                                  :key="countryCode.id"
                                  @click="selectCountry(countryCode)"
                                  class="country-code-item"
                                >
                                  {{ countryCode.nicename }} (+{{
                                    countryCode.phonecode
                                  }})
                                </div>
                              </div>
                            </div>
                            <input
                              type="text"
                              v-model="form.mobileNumber"
                              class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Mobile Number"
                              required
                            />
                          </div>
                          <div v-if="errors.mobileNumber" class="error-msg">
                            {{ errors.mobileNumber }}
                          </div>
                        </div>
                      </div>
                      <div class="flex row mt-4">
                        <!-- Revenue -->
                        <div class="relative col mr-2">
                          <label
                            class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="revenue"
                          >
                            Company Revenue
                          </label>                     
                          <div class="flex flex-col text-sm">
                            <label for="below-one-million">
                              <input
                                type="radio"
                                v-model="form.revenue"
                                id="below-one-million"
                                value="Below RM 1 million"
                                class="mr-2"
                                checked
                              />
                              Below RM 1 million
                            </label>
                            <label for="below-ten-million">
                              <input
                                type="radio"
                                v-model="form.revenue"
                                id="below-ten-million"
                                value="Below RM 10 million"
                                class="mr-2"
                              />
                              Below RM 10 million
                            </label>
                            <label for="below-fifty-million">
                              <input
                                type="radio"
                                v-model="form.revenue"
                                id="below-fifty-million"
                                value="Below RM 50 million"
                                class="mr-2"
                              />
                              Below RM 50 million
                            </label>
                            <label for="below-other">
                              <input
                                type="radio"
                                v-model="form.revenue"
                                id="below-other"
                                value="below-other"
                                class="mr-2"
                              />
                              Other
                            </label>
                          </div>
                          <input
                            v-if="form.revenue === 'below-other'"
                            type="text"
                            v-model="form.otherRevenue"
                            class="mt-4 border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Specify other revenue"
                            required
                          />
                        </div>
                      </div>
                      <!-- How can DIV Technology help you -->
                      <div class="relative row mt-4">
                        <label
                          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="wishlist"
                        >
                          How can DIV Technology help you?
                        </label>
                        <div class="flex flex-col text-sm">
                          <label for="funding">
                            <input
                              type="radio"
                              v-model="form.programmes"
                              id="funding"
                              value="Funding"
                              class="mr-2"
                              checked
                            />
                            Funding
                          </label>
                          <label for="capacity-building">
                            <input
                              type="radio"
                              v-model="form.programmes"
                              id="capacity-building"
                              value="Capacity building programmesg"
                              class="mr-2"
                            />
                            Capacity building programmes
                          </label>
                          <label for="halal">
                            <input
                              type="radio"
                              v-model="form.programmes"
                              id="halal"
                              value="Halal programmes"
                              class="mr-2"
                            />
                            Halal programmes
                          </label>
                          <label for="export">
                            <input
                              type="radio"
                              v-model="form.programmes"
                              id="export"
                              value="Export programmes"
                              class="mr-2"
                            />
                            Export programmes
                          </label>
                          <label for="bumiputera">
                            <input
                              type="radio"
                              v-model="form.programmes"
                              id="bumiputera"
                              value="Bumiputera programmes"
                              class="mr-2"
                            />
                            Bumiputera programmes
                          </label>
                          <label for="programmes-other">
                            <input
                              type="radio"
                              v-model="form.programmes"
                              id="programmes-other"
                              value="programmes-other"
                              class="mr-2"
                            />
                            Other
                          </label>
                          <input
                            v-if="form.programmes === 'programmes-other'"
                            type="text"
                            v-model="form.otherProgrammes"
                            class="mt-4 border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Specify other programmes"
                            required
                          />
                        </div>
                      </div>
                      <!-- Interested -->
                      <div class="relative row mt-4">
                        <label
                          class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                          htmlFor="interested"
                        >
                          Would you be interested to know more about this kind of event in the future:
                        </label>
                        <div class="flex flex-col text-sm">
                          <label for="yes">
                            <input
                              type="radio"
                              v-model="form.interested"
                              id="yes"
                              :value="true"
                              class="mr-2"
                              checked
                            />
                            Yes
                          </label>
                          <label for="no">
                            <input
                              type="radio"
                              v-model="form.interested"
                              id="no"
                              :value="false"
                              class="mr-2"
                            />
                            No
                          </label>
                        </div>
                      </div>
                      <!-- Consent -->
                      <div class="relative w-full my-2">
                        <input
                          type="checkbox"
                          id="consent"
                          :value="true"
                          v-model="form.consent"
                          class="mr-2"
                          required
                        />
                        <label for="consent"
                          >DIV Technology PERSONAL DATA PROTECTION POLICY: For DIV Technology to process my personal information, 
                          I/we hereby read the Personal Data Protection Notice before completing this form. 
                          By submitting this form, 
                          I/we give consent and agree to the terms of the notice appearing on the official website at
                          <a
                            href="https://www.smebank.com.my/personal-data-protection-notice"
                            target="_blank"
                            class="underline"
                            >Personal Data Protection Notice</a
                          >.</label
                        >
                      </div>
                      <div class="terms-conditions">
                        <div class="text-center mt-6">
                          <button
                            class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            type="submit"
                            :disabled="isSuccess"
                          >
                            Register
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="px-12 py-12" v-else>
                    <p class="text-center success-msg">
                      Thank you for your registration.<br /><br />Once your
                      registration is APPROVED, a confirmation email with
                      your unique registration info will be sent to your
                      registered business email address within 3 working days
                      from: DIV TECHNOLOGY
                      <a href="mailto:marketing@divtechnology.my"
                        >&lt;marketing@divtechnology.my&gt;</a
                      >
                      <br />
                      <br />
                      For any further assistance, please contact DIV TECHNOLOGY
                      marketing &amp; events team at E:
                      <a href="mailto:marketing@divtechnology.my"
                        >marketing@divtechnology.my</a
                      >
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
      </section>
    </main>
  </div>
</template>
<script>
import axios from "axios";
import topBanner from "@/assets/img/top-banner.jpg";

export default {
  data() {
    return {
      topBanner,
      isDropdownVisible: false,
      countryCodes: [],
      form: {
        firstName: "",
        lastName: "",
        companyName: "",
        nature_of_business: "",
        designation: "",
        revenue: "Below RM 1 million",
        email: "",
        programmes: "Funding",
        countryCode: "60",
        mobileNumber: "",
        interested: true,
        consent: false,
      },
      isSuccess: false,
      errors: {
        mobileNumber: "",
      },
    };
  },
  methods: {
    async submit() {
      this.validateForm();
      if (Object.values(this.errors).some((error) => error)) {
        return;
      }
      try {
        const res = await axios.post(
          process.env.VUE_APP_API_ENDPOINT + "/registration",
          {
            first_name: this.form.firstName,
            last_name: this.form.lastName,
            company_name: this.form.companyName,
            email_address: this.form.email,
            phone_code: this.form.countryCode.toString(),
            contact_number: +this.form.mobileNumber.toString(),
            nature_of_business: this.form.natureOfBusiness,
            designation: this.form.designation,
            revenue: this.form.revenue === "below-other" ? this.form.otherRevenue : this.form.revenue,
            programmes: this.form.programmes === "programmes-other" ? this.form.otherProgrammes: this.form.programmes,
            interested: this.form.interested,
            event_id: 1,
            source: this.$route.query.source,
            consent: this.form.consent
          }
        );
        if (res.status === 200) {
          this.isSuccess = true;
        }
      } catch (error) {
        console.error("Error making API request:", error);
        alert(error.response?.data?.message || 'An unexpected error occurred.');
      }
    },
    validateForm() {
      // Mobile Number
      const mobileNumberRegex = /^[1-9]\d{6,}$/;
      const isValidMobileNumber = mobileNumberRegex.test(
        this.form.mobileNumber
      );

      // Error message
      if (!isValidMobileNumber) {
        this.errors.mobileNumber = "Please enter a valid mobile number.";
      } else {
        this.errors.mobileNumber = "";
      }
    },
    async getCountryCode() {
      try {
        const res = await axios.get(
          process.env.VUE_APP_API_ENDPOINT + "/country-list"
        );
        this.countryCodes = res.data;
      } catch (error) {
        console.error("Error making API request:", error);
      }
    },
    toggleDropdown() {
      this.isDropdownVisible = !this.isDropdownVisible;
      if (this.isDropdownVisible) {
        // Add event listener to close dropdown when clicking outside
        document.addEventListener("click", this.closeDropdownOnClickOutside);
      } else {
        // Remove event listener when dropdown is closed
        document.removeEventListener("click", this.closeDropdownOnClickOutside);
      }
    },
    closeDropdownOnClickOutside(event) {
      // Check if the clicked element is outside of the dropdown and trigger element
      if (
        this.$refs.countryCodeDropdown &&
        !this.$refs.countryCodeDropdown.contains(event.target) &&
        event.target !== this.$refs.countryCodeDropdown
      ) {
        this.isDropdownVisible = false;
      }
    },
    selectCountry(countryCode) {
      this.form.countryCode = countryCode.phonecode;
      this.isDropdownVisible = false;
    },
  },
  mounted() {
    this.getCountryCode();
  },
};
</script>

<style lang="sass" scoped>
.responsive-image
  border-top-left-radius: 0
  border-top-right-radius: 0

.form-container
  width: 100%
  max-width: 967px

.radio-button-group label
  display: flex
  align-items: center

.success-msg
  color: green

.error-msg
  color: red
  font-size: 0.75rem
  padding-top: 5px

ol li
  text-align: justify

button:disabled
  opacity: 0.5
  cursor: not-allowed

ol a
  text-decoration: underline

.country-code-dropdown
  width: 80px
  cursor: pointer

.custom-dropdown
  position: absolute
  z-index: 1
  top: 100%
  left: 0
  border: 1px solid #ccc
  background-color: #fff
  max-height: 300px
  min-width: 280px
  width: auto
  overflow-y: auto
  cursor: pointer
  .country-code-item
    font-size: 14px
    padding: 5px 8px
    &:hover
      background-color: #e4e4e7

.row
  gap: 1.5rem
  .col
    width: 100%

@media (max-width: 768px)
  h4
    font-size: 1.2rem
  p, input, .country-code-dropdown
    font-size: 0.8rem
  label, button
    font-size: 0.7rem
  .terms-conditions
    p
      font-size: 0.7rem
    ol
      font-size: 0.6rem
  .row
    flex-direction: column
    .col
      margin: 0
  .desktop-break
    display: none

@media (max-width: 1024px)
  .container
    max-width: 100% !important

@media (min-width: 768px)
  .responsive-image
    border-top-left-radius: 0.5rem
    border-top-right-radius: 0.5rem
</style>
